<template>
    <div>
        <div class="right-menu shipping-right billSection">

            <div class="db-blue-section">

                <subHeader pageName="ADD BILL"/>

                <ValidationObserver ref="addBillObserver">
                    <div class="db-container">        
                        <div class="billFormMainBlock pt-0 billMainForm mt-0" id="basic-info">
                            <form id="add-bill-information" @submit.prevent="validate('add')">
                                <div class="row">

                                    <!-- vendor block starts here -->
                                    <div class="col-md-12">
                                        <div class="blogCategoryOptions billVenderName">
                                            <label for="vendor-selection">Vendor <span>*</span></label>
                                            <ValidationProvider name="vendor" rules="required" v-slot="{ errors }">
                                                <v-select 
                                                    label="name" 
                                                    @search="onVendorSearch" 
                                                    :options="vendors" 
                                                    v-model="selectedVendor" 
                                                    :filterable="false"
                                                    :disabled="tourIsActive"
                                                    id="vendor-selection"
                                                >
                                                    <template v-slot:no-options="{ search, searching }">
                                                        <template v-if="searching">
                                                            No results found for <em>{{ search }}</em>.
                                                        </template>
                                                        <em v-else style="opacity: 0.5">
                                                            Start typing to search
                                                        </em>
                                                    </template>
                                                </v-select>
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <!-- vendor block ends here -->
                
                                    <!-- bill no. block starts here -->
                                    <div class="col-md-6">
                                        <div class="formContentBox">
                                            <ValidationProvider name="bill no." rules="required|max:250" v-slot="{ errors }">
                                                <label for="billNumber">Bill No. <span>*</span></label>
                                                <input 
                                                    type="text" 
                                                    id="billNumber" 
                                                    name="billNumber" 
                                                    class="form-control" 
                                                    placeholder="Bill no."
                                                    v-model="bill.bill_no" 
                                                    :disabled="tourIsActive"
                                                >
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <!-- bill no. block ends here -->
                
                                    <!-- order no. block starts here -->
                                    <div class="col-md-6">
                                        <div class="formContentBox">
                                            <ValidationProvider name="order no." rules="max:250" v-slot="{ errors }">
                                                <label for="orderNumber">Order No.</label>
                                                <input 
                                                    type="text" 
                                                    id="orderNumber" 
                                                    name="orderNumber" 
                                                    class="form-control" 
                                                    placeholder="Order no."
                                                    v-model="bill.order_no" 
                                                    :disabled="tourIsActive"
                                                >
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <!-- order no. block ends here -->
                
                                    <!-- bill date block starts here -->
                                    <div class="col-md-6">
                                        <div class="formContentBox">
                                            <label for="billDate">Bill Date <span>*</span></label>
                                            <ValidationProvider name="bill date" rules="required" v-slot="{ errors }">
                                                <input 
                                                    type="date" 
                                                    id="billDate" 
                                                    name="billDate" 
                                                    class="form-control" 
                                                    onkeydown="return false" 
                                                    @input="checkDate()" 
                                                    :disabled="tourIsActive" 
                                                    v-model="bill.bill_date"
                                                >
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <!-- bill date block ends here -->
                
                                    <!-- due date block starts here -->
                                    <div class="col-md-6">
                                        <div class="formContentBox">
                                            <label for="dueDate">Due Date <span>*</span></label>
                                            <ValidationProvider name="due date" rules="required" v-slot="{ errors }">
                                                <input 
                                                    type="date" 
                                                    id="dueDate" 
                                                    name="dueDate" 
                                                    class="form-control" 
                                                    onkeydown="return false" 
                                                    @input="checkDate()" 
                                                    :disabled="tourIsActive" 
                                                    v-model="bill.due_date"
                                                >
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <!-- due date block ends here -->

                                </div>
                            </form>
                        </div>

                        <!-- bill items block starts here -->
                        <div class="itemDetailTable addBillBlock" id="bill-items">
                            <div class="billTableresponsiveBlock">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Item Details *</th>
                                            <th>Bill Type *</th>
                                            <th>Quantity</th>
                                            <th>Rate</th>
                                            <th>Tax</th>
                                            <th>Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item,index) in items" :key="index">
                                            <td :id="index" class="itemDetailForm"> 
                                                <div class="billItemDetailBox">
                                                    <ValidationProvider name="product" :vid="`item-name-`+index" rules="required" v-slot="{ errors }">
                                                        <multiselect
                                                            v-model="item.name"
                                                            :id="`bill-item-name-`+index"
                                                            placeholder="Type to search a product"
                                                            open-direction="bottom"
                                                            :options="products"
                                                            :multiple="false"
                                                            :searchable="true"
                                                            label="name"
                                                            track-by="name"
                                                            :loading="multiLoader"
                                                            :internal-search="false"
                                                            :clear-on-select="true"
                                                            :close-on-select="true"
                                                            :show-no-results="true"
                                                            :hide-selected="false"
                                                            :allow-empty="false"
                                                            deselect-label="Can't remove the selected item"
                                                            @search-change="asyncFind"
                                                            @select="handleSelected($event,index)"
                                                            @remove="handleRemove($event,index)"
                                                            :disabled="tourIsActive"
                                                        >
                                                            <span slot="noResult">Nothing found against the searched query.</span>
                                                        </multiselect>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </td>
                                            <td class="itemDetailForm">
                                                <div class="billItemDetailBox">
                                                    <ValidationProvider name="bill type" :vid="`item-bill-type-`+index" rules="required" v-slot="{ errors }">
                                                        <multiselect 
                                                            v-model="item.bill_type" 
                                                            :id="`item-type-`+index"
                                                            tag-placeholder="Add This as New Bill Type" 
                                                            placeholder="Search Bill Type" 
                                                            :disabled="tourIsActive" 
                                                            :options="billTypes" 
                                                            :taggable="true"
                                                            @tag="addTag"
                                                        >
                                                        </multiselect>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </td>
                                            <td>
                                                <el-input-number 
                                                    size="small" 
                                                    v-model="item.qty" 
                                                    @change="calItemAmountQty(index)" 
                                                    :disabled="tourIsActive" 
                                                    :min="1"
                                                ></el-input-number>
                                            </td>
                                            <td>
                                                <ValidationProvider name="rate" :vid="`item-rate-`+index" :rules="{required:true,regex:'^[0-9]*[.]?[0-9]*$',max_value:9999999999999}" v-slot="{ errors }">
                                                    <input 
                                                        type="number" 
                                                        step="0.01"
                                                        class="form-control" 
                                                        :id="`bill-item-rate-`+index" 
                                                        v-model="item.rate" 
                                                        @change="rateChangeEffect(index)" 
                                                        :disabled="tourIsActive"
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </td>
                                            <td>
                                                <el-input-number 
                                                    size="small" 
                                                    v-model="item.tax" 
                                                    @change="taxChangeHandle(index)" 
                                                    :disabled="tourIsActive" 
                                                    :min="0" 
                                                    :max="100"
                                                ></el-input-number>
                                            </td>
                                            <td>
                                                <ValidationProvider name="amount" :vid="`item-amount-`+index" :rules="{required:true,max_value:9999999999999}" v-slot="{ errors }">
                                                    <input 
                                                        type="number" 
                                                        step="0.01" 
                                                        :id="`bill-item-amount-`+index" 
                                                        class="form-control" 
                                                        v-model="item.amount" 
                                                        disabled
                                                    >
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </td>
                                            <td>
                                                <button type="button" @click="removeLine(index)" :disabled="tourIsActive">
                                                    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z"/>
                                                        <path d="M0 0h48v48H0z" fill="none"/>
                                                    </svg>
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="addTableRowBtn">
                                <button @click="addLine" :disabled="tourIsActive">ADD ANOTHER LINE</button>
                            </div>
                        </div>
                        <!-- bill items block ends here -->
        
                        <!-- sub total, discount and net total block starts here -->
                        <div class="subTotalContainer" id="bill-total">
                            <div class="subTotalBox">
                                <div class="txtFlexBlock">
                                    <p>Sub Total</p>
                                    <p>{{calsubTotal ? parseFloat(calsubTotal).toLocaleString('ur-PK', {style: 'currency',currency: settings.currency_unit,minimumFractionDigits: 2,maximumFractionDigits: 2,}) : 0}}</p>
                                </div>
                                <div class="txtFlexBlock">
                                    <p>Discount</p>
                                    <p>
                                        <el-input-number 
                                            size="small" 
                                            v-model="bill.discount"  
                                            @change="calDiscountedTotal()" 
                                            :disabled="tourIsActive"  
                                            :min="0" 
                                            :max="20"
                                        ></el-input-number>
                                    </p>
                                </div>
                                <div class="txtFlexBlock" style="margin-bottom: 0px">
                                    <p>Total</p>
                                    <p>{{calDiscountedPrice ? parseFloat(calDiscountedPrice).toLocaleString('ur-PK', {style: 'currency',currency: settings.currency_unit,minimumFractionDigits: 2,maximumFractionDigits: 2,}) : 0}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- sub total, discount and net total block ends here -->
        
                        <!-- bill description & attachment block starts here -->
                        <div class="attachFileRow">
                            <div class="row">
                                <div class="col-md-6" id="bill-description">
                                    <ValidationProvider ref="formProvider" name="description/note" rules="max:3000" v-slot="{ errors }">
                                        <div class="formContentBox bankDescription">
                                            <label for="billDescription">Notes or description for bill</label>
                                            <textarea 
                                                name="billDescription" 
                                                id="billDescription" 
                                                placeholder="Bill description"
                                                :disabled="tourIsActive" 
                                                v-model="bill.description"
                                            ></textarea>
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-md-6">
                                    <ValidationProvider ref="formProvider" name="attachment" rules="mimes:jpg,png,jpeg,webp,pdf,xls,csv,docx,doc" v-slot="{ errors }">
                                        <div class="billDescription">
                                            <form>
                                                <label for="uploadBill">Attach file to Bill</label>
                                                <div class="fileUploaderNameBox" v-if="bill.attachment">
                                                    <div class="fileUploader">
                                                        <div class="fileName" >
                                                            <p>{{ bill.attachment.name }}</p>
                                                        </div>
                                                        <div class="fileAction" @click.prevent="removeAttachment($event)">
                                                            <svg width="18" fill="#f00" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z"/>
                                                                <path d="M0 0h48v48H0z" fill="none"/>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="formField" v-if="!bill.attachment" id="bill-attachment">
                                                    <input 
                                                        type="file" 
                                                        :disabled="tourIsActive" 
                                                        id="uploadBill" 
                                                        ref="billFile" 
                                                        name="uploadBill" 
                                                        accept="image/png,image/jpg,image/jpeg,image/webp,application/pdf,application/msword,application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                        @change.prevent="changeImage"
                                                    >
                                                </div>
                                            </form>
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <!-- bill description & attachment block ends here -->
        
                        <!-- save bill block starts here -->
                        <div class="addTableRowBtn">
                            <button @click.prevent="goBack()" id="bill-cancel-btn" :disabled="tourIsActive">CANCEL</button>
                            <loaderBtn v-if="isLoading"/>
                            <button 
                                form="add-bill-information" 
                                type="submit"
                                @click="validate('add')" 
                                id="bill-save-btn" 
                                :disabled="tourIsActive" 
                                v-else
                            >
                                SAVE
                            </button>
                        </div>
                        <!-- save bill block ends here -->

                    </div>
                </ValidationObserver>
            
            </div>

        </div>
  
        <!-- vue tour -->
        <v-tour name="addBillsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>
  
    </div>
</template>
  
<script>
import {MessageBox, Message} from 'element-ui'
import _ from 'lodash';
import {mapGetters} from 'vuex';
import subHeader from "@/components/subHeader.vue";
import loaderBtn from "@/components/loaderBtn.vue";
  
export default {
    metaInfo() {
        return {
            title: 'Expenses/Purchases | Add Bill | YeetCommerce',
        };
    },
    data() {
        return {
            isLoading: false,
            vendors:[],
            tempVendors:[],
            billTypes:[
                "Cost Of Goods Sold",
                "IT & Internet Expenses",
                "Sales",
                "Office Supplies",
                "Inventory Asset",
                "Equipments Purchased",
                "Uncategorized"
            ],
            tempBillTypes:[],
            selectedVendor:[],
            bill:{
                vendor_id:'',
                bill_no:'',
                order_no:'',
                bill_date:'',
                due_date:'',
                items:[],
                payment_terms:'',
                discount:0,
                sub_total:0,
                total:0,
                description:'',
                attachment:null,
            },
            tempItems:{
                variation_id:'',
                name:'',
                bill_type:'',
                qty:0,
                rate:0,
                tax:0,
                amount:0,
            },
            items:[],
            itemsTemp:[],
            products:[],
            tempProducts:[],
            multiLoader:false,
            isTouched:false,
            attachmentValidations:{
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            },
            //vue-tour
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //add bill steps
                {
                    target: '#basic-info',
                    content: `<strong>Bill Information</strong><br>Basic information about the bill can be added using this block.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'bottom'
                    }
                },
                {
                    target: '#vendor-selection',
                    content: `<strong>Vendor Selection</strong><br>A vendor can be selected using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:'bottom'
                    }
                },
                {
                    target: '#billNumber',
                    content: `<strong>Bill No.</strong><br>A bill number can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:'bottom'
                    }
                },
                {
                    target: '#orderNumber',
                    content: `<strong>Order No.</strong><br>An order number can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:'bottom'
                    }
                },
                {
                    target: '#billDate',
                    content: `<strong>Bill Date</strong><br>custom date for the bill can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:'bottom'
                    }
                },
                {
                    target: '#dueDate',
                    content: `<strong>Bill Due Date</strong><br>custom due date for the bill can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement:'bottom'
                    }
                },
                {
                    target: '#basic-info',
                    content: `<strong>Bill Information</strong><br>Basic information about the bill can be added using this block.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'bottom'
                    }
                },
                {
                    target: '#bill-items',
                    content: `<strong>Bill Items</strong><br>Bill items can be added using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'bottom'
                    }
                },
                {
                    target: '#bill-total',
                    content: `<strong>Bill Calculation</strong><br>calculations for the bill total can be viewed here.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'left'
                    }
                },
                {
                    target: '#bill-description',
                    content: `<strong>Bill Description</strong><br>special notes or description for the bill can be added here.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'top'
                    }
                },
                {
                    target: '#bill-attachment',
                    content: `<strong>Bill Attachment</strong><br>Bill Attachment can be added using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'left'
                    }
                },
                {
                    target: '#bill-cancel-btn',
                    content: `<strong>Cancel</strong><br>Bill information can be discarded by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'right'
                    }
                },
                {
                    target: '#bill-save-btn',
                    content: `<strong>Save Bill Details</strong><br>Bill information can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'right'
                    }
                },
            ],
            tourIsActive:false
        }
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
        }),
        calsubTotal(){
            
            let subTotal = "0.0";
    
            this.items.forEach(item=>{
    
                let itemAmount = (parseFloat(subTotal) + parseFloat(item.amount)).toFixed(2)
        
                subTotal = itemAmount
    
            })
    
            this.bill.sub_total = subTotal
    
            return this.bill.sub_total;
    
        },
        calDiscountedPrice(){
            
            let percent = parseFloat((this.bill.discount / 100) * this.bill.sub_total).toFixed(2)
    
            this.bill.total = parseFloat(this.bill.sub_total - percent).toFixed(2) 
            
            return this.bill.total
    
        },
    },
    components: {
        subHeader,
        loaderBtn
    },
    watch:{
        'settings':{
            handler:function(val){
    
                this.items[0].tax = val.tax;
    
            },deep:true
        },
        'selectedVendor':{
            handler:function(value){
    
                if(value){
        
                    this.isTouched = true
        
                }else{
        
                    this.isTouched = false
        
                }
    
            },deep:true
        },
        'bill':{
            handler:function(value){
            
                if(value.vendor_id || value.bill_no || value.order_no || value.bill_date
                || value.due_date || value.items.length > 0 || value.payment_terms
                || value.discount > 0 || value.sub_total > 0 || value.total > 0
                || value.description || value.attachment){

                    this.isTouched = true;

                }else{

                    this.isTouched = false;
                    
                }
    
            },deep:true
        }
    },
    methods: {
        checkDate(){
    
            if(this.bill.due_date && this.bill.bill_date){
    
                var order_date = new Date(this.bill.bill_date);
                var deliver_date = new Date(this.bill.due_date);
        
                if(deliver_date < order_date){
        
                    this.$notify({
                        type: 'warning',
                        title: 'Alert',
                        message: "Can't set due date before order date.",
                    });
                    
                    this.bill.due_date = ''
        
                } 
    
            }
        },
        removeAttachment(e){
            
            this.bill.attachment = null
    
        },
        addTag(newTag){
            
            this.billTypes.push(newTag);
    
        },
        async changeImage(e){
            
            this.attachmentValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }
    
            if(e.target.files.length > 0){
            
                const file = e.target.files[0];
        
                if(file.type == 'image/jpeg' || 
                file.type == 'image/jpg' || 
                file.type == 'image/webp' || 
                file.type == 'image/png' ||
                file.type == 'application/pdf' ||
                file.type == 'application/msword' ||
                file.type == 'application/vnd.ms-excel' ||
                file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
                    
                    this.bill.attachment = file;
        
                    this.attachmentValidations.imgIsRequired = false
        
                }else if(file.type.includes('image/')){
        
                    let uploadedFileType = file.type.slice(6)
                    
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });
        
                    this.$refs.billFile.value = ''
        
                }else{
        
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image or document.",
                    }); 
        
                    this.$refs.billFile.value = ''
        
                }
            
            }
    
        },
        taxChangeHandle(index){
    
            if(this.items[index].tax > 0 && this.items[index].qty == 1){
    
                let percent = (this.items[index].tax / 100) * this.items[index].rate;
        
                this.items[index].amount = parseFloat(percent + parseFloat(this.items[index].rate)).toFixed(2);
        
            }else{
    
                let qtyRate = this.items[index].rate * this.items[index].qty;

                let percent = (this.items[index].tax / 100) * qtyRate;

                this.items[index].amount = parseFloat(percent + qtyRate).toFixed(2);
    
            }
    
        },
        calItemAmountQty(index){
    
            if(this.items[index].tax > 0){
    
                this.items[index].amount = this.items[index].rate * this.items[index].qty;

                let percent = (this.items[index].tax / 100) * this.items[index].amount;

                this.items[index].amount = parseFloat(percent + this.items[index].amount).toFixed(2);
        
            }else{
    
                this.items[index].amount = parseFloat(this.items[index].rate * this.items[index].qty).toFixed(2);
    
            }
            
        },
        rateChangeEffect(index){
    
            if(!this.items[index].rate){
    
                this.$notify({
                    type: 'warning',
                    title: 'Alert',
                    message: "Item rate field cannot be empty.",
                });
        
                this.items[index].rate = "0.00"
    
            }
    
            if(this.items[index].qty > 1 || this.items[index].tax > 0){
    
                this.items[index].rate = parseFloat(this.items[index].rate).toFixed(2);
        
                this.items[index].amount = this.items[index].rate * this.items[index].qty;

                let percent = (this.items[index].tax / 100) * this.items[index].amount;

                this.items[index].amount = parseFloat(percent + this.items[index].amount).toFixed(2);
    
            }else{
    
                this.items[index].rate = parseFloat(this.items[index].rate).toFixed(2);
        
                this.items[index].amount = this.items[index].rate;
    
            }
            
        },
        calDiscountedTotal(){
            
            let percent = (this.bill.discount / 100) * this.bill.sub_total;
            
            this.bill.total = percent + this.bill.sub_total;
    
        },
        async validate(action){
    
            if(action == "add"){
    
                this.$refs.addBillObserver.validate().then(success => {

                    if(!success){
        
                        const errors = Object.entries(this.$refs.addBillObserver.errors)
                        .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);
                        
                        this.$refs.addBillObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth",  block: "center" });
        
                    }else{
        
                        this.saveBill()
        
                    }
        
                });
    
            }
    
        },
        async saveBill(){
            
            this.bill.vendor_id = this.selectedVendor.id
            this.bill.items = this.items
    
            let formData = new FormData();
    
            formData.append('vendor_id',this.bill.vendor_id);
            formData.append('bill_no',this.bill.bill_no);
    
            formData.append('bill_date',this.bill.bill_date);
            formData.append('due_date',this.bill.due_date);
            formData.append('discount',this.bill.discount);
            formData.append('sub_total',this.bill.sub_total);
            formData.append('total',this.bill.total);
    
            if(this.bill.order_no && this.bill.order_no.length > 0 ){
    
                formData.append('order_no',this.bill.order_no);
    
            }
    
            if(this.bill.attachment){
    
                formData.append('attachment',this.bill.attachment);
    
            }
    
            for( let i= 0 ; i < this.items.length ; i++ ){

                formData.append(`items[${i}][amount]`,this.items[i].amount);
                formData.append(`items[${i}][bill_type]`,this.items[i].bill_type);
                formData.append(`items[${i}][qty]`,this.items[i].qty);
                formData.append(`items[${i}][rate]`,this.items[i].rate);
                formData.append(`items[${i}][tax]`,this.items[i].tax);
                formData.append(`items[${i}][variation_id]`,this.items[i].variation_id);
                formData.append(`items[${i}][name][name]`,this.items[i].name.name);
                formData.append(`items[${i}][name][price]`,this.items[i].name.price);
                formData.append(`items[${i}][name][variation_id]`,this.items[i].name.variation_id);

            }
    
            if(this.bill.description && this.bill.description.length > 0){

                formData.append('description',this.bill.description);

            }
    
            this.isLoading = true
            try{
                let res = await this.$axios.post('/addBill',formData);
                if(res.data.status_code == '1057'){
        
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });
        
                    this.$store.commit('bills_module/add_bills_list',res.data.bill)
        
                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);
        
                    this.$store.dispatch('gallery_module/fetchGalleryRequest');
        
                    this.$router.push({ path: '/expenses-purchases/bill'});

                }
            }catch(error){
            
                if(error.response){
        
                    if(error.response.data.error.bill_no){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.bill_no[0],
                        });

                    }else if(error.response.data.error.order_no){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.order_no[0],
                        });

                    }else if(error.response.data.error.vendor_id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error.vendor_id[0],
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{
        
                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.message,
                    });
        
                }

            }finally{
    
                this.isLoading = false
    
            }
    
        },
        addLine(){
            
            let item = {
                variation_id:'',
                name:'',
                bill_type:'',
                qty:0,
                rate:"0.00",
                tax:0,
                amount:"0.00",
            }
    
            if(this.settings){
    
                item.tax = this.settings.tax
    
            }
    
            this.items.push(item);
    
        },
        removeLine(index){
            
            if(this.items.length > 1){
    
                this.items.splice(index,1);
    
            }else{
    
                this.items = [{
                    variation_id:'',
                    name:'',
                    bill_type:'',
                    qty:0,
                    rate:"0.00",
                    tax:0,
                    amount:"0.00",
                }]
        
            }
    
        },
        asyncFind(query){
    
            if(query.length > 0){

                this.multiLoader = true
                
                this.$axios.get(`/getAllProducts`,{
                    params:{
                        search:query
                    }
                }).then(resp =>{

                    if(resp.data.products.length > 0){
                    
                        let tempProducts =[]
            
                        resp.data.products.forEach(product => {

                            product.variants.forEach(variant=>{
            
                                let p ={
                                    name:product.name + "/" + variant.name,
                                    variation_id: variant.id,
                                    price:parseInt(variant.price)
                                }
                
                                tempProducts.push(p)

                            });

                        });
            
                        this.products = tempProducts
        
                    }else{

                        this.products =[];

                    }
        
                    this.multiLoader = false

                });

            }else{

                this.products = this.tempProducts
                this.multiLoader = false

            }
            
        },

        onVendorSearch(search,loading){
            
            if(search.length > 0){
            
                loading(true);
                this.searchVendors(loading,search,this);
    
            }else{
    
                this.vendors = this.tempVendors
                loading(false);
    
            }
    
        },
        searchVendors:_.debounce(function(loading,search,vm){
        
            try{
    
                this.$axios.get(`/getVendors`,{
                    params:{
                        search: search
                    }
                }).then(resp=>{
                
                    if(resp.data.vendors.length > 0){
                    
                        this.vendors = resp.data.vendors
                    
                    }else{
        
                        this.vendors = []
        
                    }
                    
                    loading(false);
        
                });
    
            }catch(error){
    
                this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });
    
            }
            
        }),
  
        handleSelected(data, index){
    
            let foundIndex = this.items.findIndex(item => item.variation_id == data.variation_id)
    
            if(foundIndex == -1){
    
                this.items[index].name = data
                this.items[index].rate = parseFloat(data.price).toFixed(2);
                this.items[index].variation_id = data.variation_id;
                this.items[index].amount = parseFloat(data.price).toFixed(2);
    
            }else{
    
                this.$notify({
                    type: 'warning',
                    title: 'Alert',
                    message: 'Item already exists in the list.',
                });
        
                this.items[index].name = ''
    
            }
        },
        handleRemove(data,index){
    
            if(this.items.length > 1){
    
                this.items.splice(index,1);
    
            }else{
    
                this.items = [{
                    variation_id:'',
                    name:'',
                    bill_type:'',
                    qty:0,
                    rate:"0.00",
                    tax:0,
                    amount:"0.00",
                }]
                
            }
    
        },
        goBack(){
    
            if(this.isTouched){
    
                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async(result) => {
                        
                    this.$router.go(-1)
        
                }).catch(()=>{})
    
            }else{
    
                this.$router.go(-1)
    
            }
    
        },
        //tour methods
        startAddBillsTour(){
    
            this.$tours.addBillsTour.start()
    
            this.tourIsActive = true
    
        },
        handleNextStep(currentStep){
    
        },
        handlePreviousStep(currentStep){
        },
        handleTourStop(){

            this.tourIsActive = false
    
        },
        handleTourFinish(){

            this.tourIsActive = false
    
        },
        handleTourSkip(){

            this.tourIsActive = false
    
        },
    },
    mounted(){
  
        window.addEventListener('beforeunload', (event) => {
    
            if(this.isTouched){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";
            
            }
                
        });
  
    },
    async beforeMount(){
  
        let item = {
            variation_id:'',
            name:'',
            bill_type:'',
            qty:0,
            rate:"0.00",
            tax:0,
            amount:"0.00",
        };
  
        item.tax = this.settings.tax
    
        this.items.push(item);
  
        try{
    
            let res = await this.$axios.get('/getVendors');
            if(res.data.status_code == "1046"){
            
                this.vendors = res.data.vendors
                this.tempVendors = res.data.vendors
    
            }
    
            let proRes = await this.$axios.get('/getAllProducts');
            if(proRes.data.status_code == "1007"){
        
                let tempProduct =[]
                tempProduct = proRes.data.products
        
                tempProduct.forEach(product => {

                    product.variants.forEach(variant=>{
            
                        let p ={
                            name:product.name + "/" + variant.name,
                            variation_id: variant.id,
                            price:parseInt(variant.price)
                        }
            
                        this.tempProducts.push(p)
                        
                    });

                });
        
                this.products = this.tempProducts

            }
        }catch(error){
            
            this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }
      
    },
    beforeDestroy(){

    }
};
</script>
<style>
    .viewOrderBox tbody tr td {
        cursor: inherit !important;
    }
    .category-tab-img {
        height: 400px;
        width: 400px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
    .category-tab-img:hover {
        border-color: #409EFF;
    }
    .category-tab-img img{
        height: 100%;
        width: 100%;
    }
    .category-tab-img svg{
    position: absolute;
        width: 50px;
        top: 43%;
        left: 0;
        right: 0;
        margin: 0 auto;
        fill: #d9d9d9;
    }
    .category-tab-img:hover svg{
        fill: #409EFF;
    }
    .custom-form.create-section .ql-editor {
        color: #000 !important;
    }
    .ql-editor{
        height: 200px;
    }

    .billItemDetailBox .el-select{
    min-height: inherit;
    display: block;
    border-radius: 4px;
    border: 1px solid #E8E8E8 !important;
    background: #FCFCFC;
    font-size: 16px;
    font-weight: 600;
    color: #9E9B9B;
    text-transform: capitalize;
    }


    .billItemDetailBox .el-select input{
    padding-left: 15px !important;
    }
    .billItemDetailBox .el-select input::placeholder{
    color: #adadad;
    }

    /* .el-select .el-input .el-select__caret::before {
    content: "\e6e1";
    color: #848484;
    }
    .el-select .el-input.is-focus .el-select__caret {     
    transform: rotateZ(0deg);
    }
    .el-select-dropdown__empty{
    padding: 10px 20px 10px 20px;
    } */

    /* .is-focus .el-input__suffix .el-select__caret::before{
    color: #409EFF;
    } */
    
    
</style>